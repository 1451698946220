import React from 'react'

import { clientImageNames } from 'constants/ClientImagesUrls';

const ClientImageDisplayer = () => {
    return <div className="commodity-displayer-container">
        {clientImageNames.map((name, index) => {
            return <div key={index} className="client-item">
                <img src={window.StaticUrl + "img/clients/" + name} 
                     className="client-img" />
            </div>
        })}
    </div>

}

export default ClientImageDisplayer