import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Alert } from "reactstrap";
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { submit_new_phone_number } from 'util/APICall'
import DialCodeOptions from 'constants/DialCodeOptions'

import _ from 'lodash'

import AccountHomeButton from "./account-home-button";

class ResetPhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      loading: false
    };
  }

  onClickSubmit = (event, values) => {
    const telephone_dial_code = values.telephone_dial_code
    const telephone_number = values.telephone_number
    const confirm_telephone_number = values.confirm_telephone_number

    this.setState({
      error: false,
      errorMessage: "",
      loading: true
    })

    if (telephone_number !== confirm_telephone_number) {
      this.setState({
        error: true,
        errorMessage: "Phone numbers do not match",
        loading: false
      })

      return
    }

    // submit the new phone number
    submit_new_phone_number(
      this.props.email,
      this.props.password,
      telephone_dial_code,
      telephone_number,
    ).then(
      response =>
        // if the response is successful, call the onSuccess function
        this.props.onSuccess()
    ).catch(err => {
      let errMsg = ""

      // otherwise try to display an error msg
      if (err && err !== null && err.response && err.response && err.response.data.data) {
        errMsg = err.response.data.data.message
      } else {
        errMsg = err.message
      }
      this.setState({ error: true, errorMessage: errMsg, loading: false })
    })
  }

  debouncedClickSubmitHandler = _.debounce(this.onClickSubmit, 750)

  render() {
    return (
      <React.Fragment>

        {/* render home button */}
        <AccountHomeButton />

        <section className="height-100vh">
          <div className="display-table">
            <div data-testid="signin_form" className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="5">
                    <Card className="account-card">
                      <CardBody>
                        <div className="text-center mt-3">
                          <h3 className="font-weight-bold"><a data-testid="link_logo_go_to_landing" href="/" className="text-dark text-uppercase account-pages-logo"><img src={window.StaticUrl + "aura-landing/img/logo.png"} alt="" /></a></h3>
                          <p className="text-muted">Update your phone number</p>
                          <p className="text-muted">Please fill in your dial code and phone number</p>
                        </div>
                        <div className="p-3">
                          <AvForm onValidSubmit={this.debouncedClickSubmitHandler}>
                            <AvGroup className="form-group">
                              <Row className="justify-content-start">
                                <Col lg="4" sm="12">
                                  <AvField data-testid="dial_code_field" type="select" name="telephone_dial_code" label="Dial Code"
                                    className="standard-select"
                                    required
                                    errorMessage=""
                                    validate={{
                                      required: { value: true, errorMessage: "Please enter the dial code for your phone number" },
                                    }}
                                    value={"+44"}
                                  >
                                    {
                                      DialCodeOptions.map((item) => (
                                        <option data-testid="dial_code_select_option"
                                          key={item['value'] + item['label']}
                                          value={item['value']}>{item['label']}</option>
                                      ))
                                    }
                                  </AvField>
                                </Col>
                              </Row>
                              <Row className="justify-content-center">
                                <Col lg="12" sm="12">
                                  <AvField data-testid="phone_number_field" label="Phone Number" type="text" className="form-group mt-2" name="telephone_number" id="telephone_number" placeholder="Phone number" required
                                    errorMessage=""
                                    validate={{
                                      required: { value: true, errorMessage: "Please enter your phone number" },
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row className="justify-content-center">
                                <Col lg="12" sm="12">
                                  <AvField data-testid="confirm_phone_number_field" label="Confirm Phone Number" type="text" className="form-group mt-2" name="confirm_telephone_number" id="confirm_telephone_number" placeholder="Confirm Phone number" required
                                    errorMessage=""
                                    validate={{
                                      required: { value: true, errorMessage: "Please enter your phone number" },
                                    }}
                                  />
                                </Col>
                              </Row>
                            </AvGroup>

                            {this.state.error == true && <Alert color="danger">
                              {this.state.errorMessage}
                            </Alert>
                            }

                            <div className="mt-3">
                              <Button data-testid="submit_button" type="submit" className="btn btn-custom btn-block">Submit</Button>
                            </div>
                          </AvForm>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>

    );
  }
}

export default ResetPhoneNumber;
