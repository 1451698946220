import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

class CaseStudyBox extends Component {
    render() {
        return (
            <React.Fragment>
              <Col lg="12" style={{  height: '400px' }}>
                  <div className="blog-box" style={{ display: "flex", justifyContent: "center" }}>
                      <Row style={{ width: "100%" }}>
                        <Col lg="8" xs="12">
                          <img
                            src={this.props.casestudy.image}
                            className="img-fluid rounded"
                            alt=""
                            style={{ width: "auto", maxHeight: "800px" }}
                          />
                        </Col>
                        <Col lg="4" xs="12" style={{ textAlign: "left", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <div>
                              <h3 className="mt-3">{this.props.casestudy.title}</h3>
                              <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                                  {
                                    this.props.casestudy.points.map((point, index) => (
                                      <li key={index} className="">{point}</li>
                                    ))
                                  }
                              </ul>
                          </div>
                        </Col>
                      </Row>
                  </div>
              </Col>
            </React.Fragment>
        );
    }
}

export default CaseStudyBox;
