import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'assets/css/Popup.css'
import 'assets/css/CookiePopup.css'
import 'assets/css/navbar.css'

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
window.StaticUrl = "https://d19k9v5t7eclpj.cloudfront.net/";
ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
