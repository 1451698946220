import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
    state = {
        services : [
            { title : "Wholesale / Distributor", icon : "pe-7s-box1", description : "" },
            { title : "Hedge Fund", icon : "pe-7s-graph1", description : "" },
            { title : "Insurance", icon : "pe-7s-news-paper", description : "" },
            { title : "Food & Beverage", icon : "pe-7s-coffee", description : "" },
            { title : "CTA", icon : "pe-7s-chat", description : "" },
            { title : "Bank", icon : "pe-7s-culture", description : "" },
            { title : "Physical Trading", icon : "pe-7s-users", description : "" },
            { title : "Asset Manager", icon : "pe-7s-portfolio", description : "" },
            { title : "Consulting", icon : "pe-7s-phone", description : "" },
            { title : "Producer / Farmer", icon : "pe-7s-leaf", description : "" },
            { title : "Proprietary Trading", icon : "pe-7s-display1", description : "" },
            { title : "Academic", icon : "pe-7s-calculator", description : "" },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="services">
                    <Container>

                        {/* Render section title */}
                        <SectionTitle title="Who is Aura for?" description=""/>

                        <Row className="margin-t-30">
                            {/* render service box */}
                            {
                                this.state.services.map((service) =>
                                    <ServiceBox key={service.title}
                                                title={service.title} 
                                                icon={service.icon} 
                                                description={service.description} />
                                )
                            }
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Services;
