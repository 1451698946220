import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Input } from "reactstrap";
import Mailchimp from 'react-mailchimp-form'

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
     constructor(props) {
        super(props);
        this.state = {
        footerItems : [
            { title : "Aura Commodities" ,
                links : [
                    { linkTitle : "Terms and Conditions", link : "/terms-of-use" },
                    { linkTitle : "Privacy Policy", link : "/privacy-policy" },
                    { linkTitle : "Cookie Policy", link : "/cookie-policy" },
                ]
            },
        ],
        isSwitchToggle : false
    }
    this.toggleSwitcher = this.toggleSwitcher.bind(this);
    this.setTheme = this.setTheme.bind(this);
    }

    setTheme = (color) => {
        document.getElementById("colorTheme").href="assets/colors/"+color+".css";
    }

toggleSwitcher = () => {
    this.setState({ isSwitchToggle : !this.state.isSwitchToggle });
}
    render() {
        return (
            <React.Fragment>

                <FooterLinks/>

            </React.Fragment>
        );
    }
}

export default Footer;
