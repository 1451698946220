import React from 'react'

const CookiePopup = ({ onClickAccept, onClickPrivacyPlicy }) => {
    return <div className="cookie-container">
        <span><strong>Cookies Policy</strong></span>
        <p>This website uses cookies to ensure you get the best experience on our website. <button className="footer-link" onClick={() => onClickPrivacyPlicy()}>Click here to view our cookie policy.</button></p>
        <button type="button" className="btn-custom navbar-btn btn-rounded waves-effect waves-light btn btn-secondary" onClick={onClickAccept}>I agree</button>
    </div>
}

export default CookiePopup
