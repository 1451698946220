export const isValidValue = (value) => {
    return (value && value !== null && value.trim().length > 0)
}


export const extractEmailDomain = (email) => {
    if (email.indexOf('@') === -1)
        return 'auracommodities'

    const address = email.split('@').pop()
    return address.split('.').shift()
}