const EmployeePositionOptions = [
    { "value": "Procurement / Purchasing manager", "label": "Procurement / Purchasing manager" },
    { "value": "Physical Trader / Merchandiser", "label": "Physical Trader / Merchandiser" },
    { "value": "Paper Trader / Portfolio Manager", "label": "Paper Trader / Portfolio Manager" },
    { "value": "Analyst / Researcher", "label": "Analyst / Researcher" },
    { "value": "Executive", "label": "Executive" },
    { "value": "Broker", "label": "Broker" },
    { "value": "Operations / Logistics", "label": "Operations / Logistics" },
    { "value": "Risk Manager", "label": "Risk Manager" },
    { "value": "Farmer", "label": "Farmer" },
    { "value": "Other", "label": "Other" }
]

export default EmployeePositionOptions
