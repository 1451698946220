import React, { Component } from 'react';
import { Container, Row, Col, Button } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Mailchimp from 'react-mailchimp-form'

//Import Section Title
import SectionTitle from "../common/section-title";

class ContactUs extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section " id="contact">
                    <Container>
                        <Row>
                            <Col lg="4">
                                <div className="mt-4 pt-4 small-center">
                                    <p className=""><span className="h5">London Headquarters:</span><br/> <span className="text-muted d-block mt-2">17 Hanover square, Mayfair,<br></br> London, W1S 1BN, United Kingdom</span></p>
                                    <p>info@auracommodities.com</p>
                                </div>
                            </Col>
                            <Col lg="8">
                                <div className="subscribe-container custom-form mt-4 pt-4">
                                    <div className="message">Get insights delivered to your inbox</div>
                                    <Mailchimp
                                      id="contact-form"
                                      className="form subscribe"
                                      action='https://auracommodities.us4.list-manage.com/subscribe/post?u=c874bc3ec17fe4ef203294a69&amp;id=372d6441a0'
                                      fields={[
                                        {
                                          name: 'EMAIL',
                                          placeholder: 'Email',
                                          type: 'email',
                                          required: true
                                        }
                                      ]}
                                      messages = {
                                          {
                                            sending: "Sending...",
                                            success: "Thank you for subscribing!",
                                            error: "An unexpected error has occurred, please try again later.",
                                            empty: "You must write an e-mail.",
                                            duplicate: "Too many subscribe attempts for this email address",
                                            button: "Submit"
                                          }
                                        }
                                      />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default ContactUs;
