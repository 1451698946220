import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, Alert } from "reactstrap";

//Import Home Button
import AccountHomeButton from "./account-home-button";

class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorMessage: ""
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        document.body.classList.add("bg-account-pages");
        document.body.classList.add("py-4");
        document.body.classList.add("py-sm-0");
    }

    render() {
        return (
            <React.Fragment>

                {/* render home button */}
                <AccountHomeButton />

                <section className="height-100vh">
                    <div className="display-table">
                        <div className="display-table-cell">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col lg="12">
                                        <Card className="account-card">
                                            <CardBody>
                                                <div className="text-center mt-3">
                                                    <h3 className="font-weight-bold"><a href="/" className="text-dark text-uppercase account-pages-logo"><img src={window.StaticUrl + "aura-landing/img/logo.png"} alt="" /></a></h3>
                                                    <p className="text-muted">Privacy Policy</p>
                                                </div>
                                                <div className="p-3">
                                                    <ol>
                                                        <li><strong>WHO ARE WE AND WHAT IS AURA COMMODITIES?</strong>
                                                            <ol>
                                                                <li>We are Aura Commodities Ltd, a company registered in England and Wales under company number 10088267 and we have our registered office at 17 Hanover Square, Mayfair, London, England, W1S 1BN <strong>(we, us, our)</strong>.</li>
                                                                <li>We provide a software as a service specialising in commodities and cartography data (currently available at <a href="https://auracommodities.com/" target="_blank">https://auracommodities.com/</a>). Any reference in this notice to <strong>Aura Commodities</strong> means the website made available at that link, including this SaaS.</li>
                                                                <li>If you have any questions about who we are, what we do, or the provisions set out in this notice (Privacy Notice), please feel free to contact us at the address above or by email to <span className="link-view">info@auracommodities.com</span>.</li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>WHAT DOES THIS PRIVACY NOTICE DO?</strong>
                                                            <ol>
                                                                <li>In order to provide Aura Commodities, we may need to process <strong>Personal Data</strong> from time to time (information from which an individual can be identified). If we hold this data as a <strong>Controller</strong> (which means we make decisions about what data to collect, and how it should be used), we are required to provide anyone who can be identified from that data with a notice explaining how we use Personal Data about them. That’s what this document is for – to tell you about how we process Personal Data of our Users.</li>
                                                                <li>We might need to change this Privacy Notice from time to time. In particular, we may need to change it to cater for any legislative changes which result from the United Kingdom’s exit from the European Union. We will post any important changes in the CHANGES TO THIS PRIVACY NOTICE section above, so please do keep an eye on this Privacy Notice before sending us any Personal Data or uploading it on to Aura Commodities.</li>
                                                                <li>All of the defined terms in this Privacy Notice are explained in paragraph 12 below. If you have any questions about this Privacy Notice, feel free to send us an email to <span className="link-view">info@auracommodities.com</span>.</li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>WHO DO WE HOLD PERSONAL DATA ABOUT?</strong>
                                                            <ol>
                                                                <li>In the provision of Aura Commodities, we will hold Personal Data about two types of User:
                                                                    <p><strong>(i)&nbsp;&nbsp;open account holder:</strong> an account holder with an active and properly paid-up subscription to use the core Aura Commodities SaaS, as well as a trial user whose access to the core Aura Commodities SaaS has been approved by us;</p>
                                                                    <p><strong>(ii)&nbsp;&nbsp;dormant account holder:</strong> a visitor to the Aura Commodities website at <a href="https://auracommodities.com/" target="_blank">https://auracommodities.com/</a> , as well as a former open account holder.</p>
                                                                    <p>Each open account holder will be required to set up a User Account and to do so will be required to provide us with some information about themselves. Dormant account holders won’t be required to provide the same information, but we are likely to collect additional information about how dormant account holders interact with Aura Commodities. Finally, there are a number of specific scenarios in which we collect Personal Data, as outlined in section 4 below. All of these types of data are referred to in this Privacy Notice as User Data. Since we use this data for our own business purposes, we are a Controller in respect of how we use it. This Privacy Notice sets out what <strong>User Data</strong> we collect and how we use it.</p>
                                                                </li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>WHAT TYPES OF PERSONAL DATA DO WE COLLECT ABOUT OUR USERS AND WHERE DO WE COLLECT IT FROM?</strong>
                                                            <ol>
                                                                <li>We may collect or obtain User Data in the following ways:
                                                                    <p><strong>(i)&nbsp;&nbsp;User Data which a User uploads on to Aura Commodities when they set up a User Account.</strong>This will include name and contact data, password and a profile image if the User chooses to submit one, your occupation, the city or town you live in and past job experiences.</p>
                                                                    <p><strong>(ii)&nbsp;&nbsp;User Data which a User provides to us if they contact us with a query.</strong>This would include details of their communication.</p>
                                                                    <p><strong>(iii)&nbsp;&nbsp;User Data which other Users may provide about you.</strong>We may receive information <strong>(Comment Data)</strong>about you from other Users, for example as part of any comments posted by them on Aura Commodities. We require each of these Users to have lawful rights to collect, use and share your information before providing any information to us.</p>
                                                                    <p><strong>(iv)&nbsp;&nbsp;User Data a User provides us with in respect of their marketing preferences.</strong>This is likely to include contact details and marketing preferences.</p>
                                                                    <p><strong>(v)&nbsp;&nbsp;Usage data which is automatically collected by us about how someone interacts with Aura Commodities.</strong>This may include IP address, login data, browser type and version, time zone setting and location, browser plugin types and versions, operating system and platform and other technology on the devices used to access Aura Commodities. This data may be collected through the cookies we use or other technology. If you would like to know more about our cookies policy, please click <a hrep="" target="_blank">here</a>.</p>
                                                                    <p><strong>(vi)&nbsp;&nbsp;We may also collect, use and share aggregated data such as statistical or demographic data which we collect from interactions with Users.</strong> Aggregated data may be derived from Personal Data but, since it cannot be used to identify an individual, it is not Personal Data.</p>
                                                                </li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>HOW DO WE USE THE PERSONAL DATA WE HOLD AND WHAT IS OUR LAWFUL BASIS FOR DOING SO?</strong>
                                                            <ol>
                                                                <li>We process data about Users for the following purposes:
                                                                    <p><strong>(i)&nbsp;&nbsp;To provide our Users with access to Aura Commodities.</strong>This may include storing log in details and previously supplied User Data on behalf of a User as well as facilitating payment if you have opted to use a paid-for function on Aura Commodities. Any such use would be to the extent necessary for the performance of our contract with you.</p>
                                                                    <p><strong>(ii)&nbsp;&nbsp;To improve the interactive service offered by Aura Commodities. </strong>For example, we store Comment Data to enable third party insight and information. This is necessary to protect our legitimate interests of running our business.</p>
                                                                    <p><strong>(iii)&nbsp;&nbsp;To manage our relationship with our Users. </strong>This may include notifying Users of updates to Aura Commodities. This is necessary to protect our legitimate interests of running our business.</p>
                                                                    <p><strong>(iv)&nbsp;&nbsp;For administration and dispute resolution purposes.</strong>This may include processing Personal Data to meet our internal administration requirements and for matters such as dispute resolution.  This is necessary to protect our legitimate interests of running our business.</p>
                                                                    <p><strong>(v)&nbsp;&nbsp;For marketing purposes.</strong>From time to time we might contact our Users by telephone or email about updates to Aura Commodities, new features or functions or new products we are bringing out. Our marketing may be tailored on the basis of what we think your interests are (from looking at past transactions and interactions). We will always include the right to opt out in any such correspondence. Generally we will rely on the fact that this is necessary to protect our legitimate interests of running our business, however where required by law we will obtain your consent.</p>
                                                                    <p><strong>(vi)&nbsp;&nbsp;</strong>We may use usage data <strong>to monitor account usage and manage disputes.</strong> Such use is necessary for us to achieve our legitimate interest of protecting the integrity of our software. If a User does not use Aura Commodities in accordance with our terms of use, we may cease allowing them to access Aura Commodities and we may pass on the User’s details if such activities are or are likely to be in breach of someone else’s rights of privacy, intellectual property rights or any other lawful rights. This is necessary to protect our legitimate interests of running our business.</p>
                                                                </li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>WILL WE DISCLOSE PERSONAL DATA TO ANYONE ELSE?<br /></strong>
                                                            <span style={{ textDecoration: 'underline' }}><strong>Data Processors</strong></span>
                                                            <ol>
                                                                <li>We may disclose any Personal Data that we hold to our employees, as well as to other third parties who we engage to help us provide our Aura Commodities. For example, we use third parties to provide the following services:
                                                                    <ul style={{ listStyleType: 'disc' }}>
                                                                        <li>Email provider – VIADUC</li>
                                                                        <li>Host server provider – AMAZON EU</li>
                                                                        <li>Marketing database provider – SALES FORCE</li>
                                                                        <li>Marketing insights - MIXPANEL</li>
                                                                    </ul>
                                                                    <p>
                                                                        Any such parties contracted by us will be acting as our Processors and will be subject to strict contractual requirements only to use Personal Data in accordance with this Privacy Notice. If you would like more information about third party processors used by us, please contact us at: <span className="link-view">info@auracommodities.com</span>.
                                                                    </p>
                                                                </li>

                                                                <span style={{ textDecoration: 'underline' }}><strong>Information published on Aura Commodities</strong></span>
                                                                <li>The nature of our Aura Commodities means that:
                                                                    <ol>
                                                                        <li>if you are referred to in any Comment Data, the Comment Data will be accessible by any open account holder; and</li>
                                                                        <li>if you enrol as an open account holder, any other open account holder who accesses Aura Commodities will be able to see your User profile. For this reason you should only add content to your User Account which you are happy to share (or have shared) with other people.</li>
                                                                    </ol>
                                                                </li>
                                                                <p><strong>This Privacy Notice only deals with how we at Aura Commodities handle Personal Data. Other Users are not bound by this Privacy Notice. You should only upload information that you are comfortable to share (or have shared) with other people.</strong></p>

                                                                <span style={{ textDecoration: 'underline' }}><strong>Other Disclosures</strong></span>
                                                                <li>We may also disclose Personal Data:
                                                                    <ol>
                                                                        <li>in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements or to protect the operation of Aura Commodities, or the rights, property, or safety of us, our Users, or others; or</li>
                                                                        <li>to any buyer if we sell, transfer or merge parts of our business or our assets. If a change happens to our business, then the new owners will only be entitled to use Personal Data in accordance with the provisions set out in this Privacy Notice.</li>
                                                                    </ol>
                                                                </li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>WHAT SECURITY PROCEDURES DO WE HAVE IN PLACE?</strong>
                                                            <ol>
                                                                <li>It is our policy to ensure that all Personal Data held by us is handled correctly and appropriately on a need to know basis according to the nature of the information, the risk associated with mishandling the data, including the damage that could be caused to an individual as a result of loss, corruption and/or accidental disclosure of any such data, and in accordance with applicable legal requirements.</li>
                                                                <li>There are some steps you can take to help make sure that your data is protected. For example:
                                                                    <p>(a)&nbsp;&nbsp;if you are sending any financial details or sensitive information, consider sending it in separate emails or encrypted, password protected documents;</p>
                                                                    <p>(b)&nbsp;&nbsp;make sure that you keep any passwords associated with your Aura Commodities account secure; and</p>
                                                                    <p>(c)&nbsp;&nbsp;make sure you understand who can access the data you upload on to your User Account before you add any information which might be shared. For more information on this, please have a look at paragraph 6.2 above.</p>
                                                                </li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>WHERE DO WE STORE THE PERSONAL DATA WE COLLECT?</strong>
                                                            <ol>
                                                                <li>For Users based in the EEA, we only use servers in [the EEA (and Britain)]. Our current host servers are provided by Amazon EU, whose servers are based in London, England. </li>
                                                                <li>Some of our Processors may be based outside the EEA. We will only use Processors who ensure that they have adequate safeguards in place to protect Personal Data relating to you.</li>
                                                                <li>If you are based outside the EEA and would like further information about where we hold your data, please contact us by email: info@Auracommodities.com.</li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>FOR HOW LONG DO WE STORE PERSONAL DATA?</strong>
                                                            <ol>
                                                                <li>You can delete your User Account at any time. If you do, you will no longer have access to Aura Commodities. We may continue to hold data about you in accordance with our retention policy, which is set out below.</li>
                                                                <li>Our retention policies for User Data are as follows:
                                                                    <p>(a)	we may store your User Data for a period of 1 year after termination of your User Account. This is to make it easy for you to sign up again if you choose to do so;</p>
                                                                    <p>(b)	we may store User Data related to financial transactions for up to 7 years to ensure that we have sufficient records from an accounting and tax perspective;</p>
                                                                    <p>(c)	we may archive User Data relating to negotiations, contracts agreed, payments made, disputes raised for up to 6 years to protect ourselves in the event of a dispute arising between you and us;</p>
                                                                    <p>(d)	we may retain User Data which is held for marketing purposes for up to 5 years from the date you opt in – this is subject to your right to opt out at any time;</p>
                                                                    <p>(e)	we may store aggregate data without limitation (on the basis that no individual can be identified from the data); and</p>
                                                                    <p>(f)	we may retain usage User Data for a period of up to 6 years after expiry of the relevant User contract in case of any disputes arising.</p>
                                                                </li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>WHAT RIGHTS DO YOU HAVE IN RELATION TO THE PERSONAL DATA WE HOLD?</strong>
                                                            <ol>
                                                                <li>Every User has the following rights in respect of any User Data we hold about them:
                                                                    <p><strong>(a)	Right to be informed: </strong>the right to be informed about what Personal Data we collect and store and how it’s used.</p>
                                                                    <span><strong>(b)	Right of access: </strong>the right to request a copy of the Personal Data we hold, as well as confirmation of:
                                                                        <ul style={{ listStyleType: 'none' }}>
                                                                            <li>(i)	the purposes of the processing;</li>
                                                                            <li>(ii)	the categories of personal data concerned;</li>
                                                                            <li>(iii)	the recipients to whom the personal data has/will be disclosed;</li>
                                                                            <li>(iv)	for how long it will be stored; and</li>
                                                                            <li>(v)	if data wasn’t collected directly from you, information about the source.</li>
                                                                        </ul>
                                                                    </span>
                                                                    <p><strong>(c)	Right of rectification: </strong>the right to require us to correct any Personal Data we hold which is inaccurate or incomplete.</p>
                                                                    <p><strong>(d)	Right to be forgotten: </strong>in certain circumstances, the right to have the Personal Data we hold erased from our records. </p>
                                                                    <p><strong>(e)  Right to restriction of processing: </strong>the right to request us to restrict the processing we carry out. You might want to do this, for instance, if you think the data we hold is inaccurate and you would like to restrict processing the data has been reviewed and updated if necessary.</p>
                                                                    <p><strong>(f)	Right of portability: </strong>the right to have the Personal Data we hold transferred to another organisation, to the extent it was provided in a structured, commonly used and machine-readable format.</p>
                                                                    <p><strong>(g)	Right to object to direct marketing: </strong>the right to object where processing is carried out for direct marketing purposes (including profiling in connection with that purpose).</p>
                                                                    <p><strong>(h)	Right to object to automated processing: </strong>the right not to be subject to a decision based solely on automated processing (including profiling) which produces legal effects (or other similar significant effects) on you.</p>

                                                                </li>
                                                                <li>If you want to avail of any of these rights, you should contact us immediately at <span className="link-view">info@auracommodities.com</span>. If we are not the Controller of the Personal Data (for example, where User Data has been posted as Comment Data), we will need to transfer your request to the Controller – but we will only do so with your consent. If you do contact us with a request, we will also need evidence that you are who you say you are to ensure compliance with data protection legislation.</li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>WHO DO YOU COMPLAIN TO IF YOU’RE NOT HAPPY WITH HOW WE PROCESS PERSONAL DATA ABOUT YOU?</strong>
                                                            <ol>
                                                                <li>If you have any questions or concerns about how we are using Personal Data about you, please contact our data protection officer immediately at our address (see paragraph 1.1 above) or by email to <span className="link-view">info@auracommodities.com</span>. If we are processing Personal Data about you on behalf of a User, we will need to pass your complaint to our User – we will only do so with your consent.</li>
                                                                <li>If you wish to make a complaint about how we have handled Personal Data about you, you may lodge a complaint with the Information Commissioner’s Office by following this link: <a href="https://ico.org.uk/concerns/" target="_blank">https://ico.org.uk/concerns/</a>.</li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>WHAT DO ALL OF THE DEFINED TERMS IN THIS PRIVACY NOTICE MEAN?</strong>
                                                            <ol>
                                                                <li>	Throughout this Privacy Notice you’ll see a lot of defined terms (which you can recognise because the first letter is capitalised). Where possible, we’ve tried to define them as we go, but we thought it might be useful to have a glossary as well. Anywhere in this Privacy Notice you see the following terms, they’ll have the following meanings:
                                                                    <p><strong>Aura Commodities </strong>means the website located at <a href="https://Auracommodities.com/" target="_blank">https://Auracommodities.com/</a>, including the software as a service specialising in commodities and cartography data available at that website (which is only available for use by open account holders).</p>
                                                                    <p><strong>Comment Data </strong>means User Data about a third party posted by an open account holder in the course of their use of Aura Commodities.</p>
                                                                    <p><strong>Controller </strong>is a legal term set out in the General Data Protection Regulation 2016/679, meaning the party responsible for deciding what Personal Data to collect and how to use it;</p>
                                                                    <p><strong>dormant account holder </strong>means a visitor to the Aura Commodities website, as well as a former open account holder, each of whom do not have a current subscription to access the Aura Commodities SaaS;</p>
                                                                    <p><strong>EEA </strong>means the European Union, Iceland, Liechtenstein and Norway;</p>
                                                                    <p><strong>open account holder </strong>means an account holder with an active and properly paid-up subscription to use the Aura Commodities, as well as a trial user whose access to the core Aura Commodities SaaS has been approved by us;</p>
                                                                    <p><strong>Personal Data </strong>means data which can be used to identify a living individual. This could be a name and address or it could be a number of details which when taken together make it possible to work out who the information is about. It also includes any information about the identifiable individual;</p>
                                                                    <p><strong>Processor </strong>means a party who has agreed to process Personal Data on behalf of a Controller and in accordance with their instructions;</p>
                                                                    <p><strong>User </strong>means a user of Aura Commodities, being either an open account holder or a dormant account holder;</p>
                                                                    <p><strong>User Account </strong>means the account set up by an open account holder so they can use Aura Commodities;</p>
                                                                    <p><strong>User Data </strong>means Personal Data about Users of Aura Commodities.</p>
                                                                </li>
                                                            </ol>
                                                        </li>

                                                    </ol>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        );
    }
}

export default Privacy;
