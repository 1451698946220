import React from 'react';
import Intercom from 'react-intercom';

class ChatBotWidget extends React.Component {
    render() {
        return (
            <div className="app">
                <Intercom appID={process.env.REACT_APP_INTERCOM_APP_ID}  />
            </div>
        );
    }
}

export default ChatBotWidget
