import axios from 'axios'
import {
  REQUEST_2FA_CODE,
  USER_LOGIN,
  USER_SIGNUP,
  GEO,
  EMAIL_DOMAIN_CHECK,
  RESET_PHONE_NUMBER
} from '../constants/APIUrls'

export const login = (username, password, two_factor_exemption_code) => {
  return axios.post(process.env.REACT_APP_API_URL + USER_LOGIN, {
    username, password, two_factor_exemption_code
  })
}

export const signup = (user) => {
  return axios.post(process.env.REACT_APP_API_URL + USER_SIGNUP, user)
}

export const user_country = () => {
  return axios.get(GEO)
}

export const check_email_domain = (domain) => {
  return axios.get(process.env.REACT_APP_API_URL + EMAIL_DOMAIN_CHECK, {
    params: { domain }
  })
}

export const request_two_factor_auth_code = (username, password, channel) => {
  return axios.post(
    process.env.REACT_APP_API_URL + REQUEST_2FA_CODE(channel),
    { username, password }
  )
}

export const submit_two_factor_auth = (username, password, two_factor_code) => {
  return axios.post(process.env.REACT_APP_API_URL + USER_LOGIN, {
    username, password, two_factor_code
  })
}

export const submit_new_phone_number = (username, password, telephone_dial_code, telephone_number) => {
  return axios.patch(process.env.REACT_APP_API_URL + RESET_PHONE_NUMBER, {
    username, password, telephone_dial_code, telephone_number
  })
}
