import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, Alert } from "reactstrap";

//Import Home Button
import AccountHomeButton from "./account-home-button";

class CookiePolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
          error: false,
          errorMessage: ""
        };
    }

    componentDidMount() {
      window.scrollTo(0, 0);
    }

    componentWillMount() {
        document.body.classList.add("bg-account-pages");
        document.body.classList.add("py-4");
        document.body.classList.add("py-sm-0");
    }

    render() {
        return (
            <React.Fragment>

                {/* render home button */}
                <AccountHomeButton/>

                <section className="height-100vh">
                    <div className="display-table">
                        <div className="display-table-cell">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col lg="12">
                                        <Card className="account-card">
                                            <CardBody>
                                                <div className="text-center mt-3">
                                                    <h3 className="font-weight-bold"><a href="/" className="text-dark text-uppercase account-pages-logo"><img src={window.StaticUrl + "aura-landing/img/logo.png"} alt="" /></a></h3>
                                                    <p className="text-muted">Cookie Policy</p>
                                                </div>
                                                <div className="p-3">
                                                <p>We use cookies as part of our website, <a href="https://auracommodites.com/">https://auracommodites.com/</a> <strong>(“Site”)</strong>. This policy sets out what cookies are, what cookies we use and why.</p>
                                                <ol>
                                                  <li><strong>WHAT IS A COOKIE?</strong>
                                                    <ol>
                                                      <li>A cookie is a small text file containing anonymous information (letters and numbers) which acts as an identifier that will be sent by our server to your computer or mobile device when you use our Site.</li>
                                                      <li>Our website uses cookies to distinguish you from other users of our website. For instance, our Site will be able to remember your preferred settings, user name and preferences, saving you time each time you log in. This means we are able to provide you with a good browsing experience and means we can continue to improve our website.</li>
                                                    </ol>
                                                  </li>

                                                  <li><strong>TYPES OF COOKIE</strong>
                                                    <ol>
                                                      <li>In our provision of services to you, we use both ‘essential’ and ‘non-essential’ cookies.
                                                        <ol>
                                                          <li><strong>ESSENTIAL COOKIES</strong>
                                                            <p>Some cookies are required to perform essential functions on our Site. We use essential cookies for the purposes such as:</p>
                                                            <ul style={{ listStyleType: 'disc' }}>
                                                              <li>to authenticate users and to recognise you when you return to our Site. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region). They also allow us to provide notifications and identify you on the servers of our third party service providers;</li>
                                                              <li>to store browser session;</li>
                                                              <li>to identify users on the chat function;</li>
                                                              <li>to enable users to access private information for the duration of their visit;</li>
                                                              <li>for the administration of the services. </li>
                                                            </ul>
                                                          </li>

                                                          <li><strong>NON-ESSENTIAL COOKIES</strong>
                                                            <p>The table below explains the non-essential cookies we use and why</p>
                                                            <table>
                                                              <thead>
                                                                <tr>
                                                                  <th><strong>Owner Cookie</strong></th>
                                                                  <th><strong>Cookie Name</strong></th>
                                                                  <th><strong>Cookie Description</strong></th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                <tr>
                                                                  <td>Google Analytics</td>
                                                                  <td>_utma</td>
                                                                  <td>This keeps track of the number of times a visitor has been to the site, when their first visit was, and when their last visit occurred.</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>Google Analytics</td>
                                                                  <td>_utmb</td>
                                                                  <td>This creates a timestamp of the exact moment when a visitor enters site.</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>Google Analytics</td>
                                                                  <td>_utmc</td>
                                                                  <td>This creates a timestamp of the exact moment when a visitor leaves the site.</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>Google Analytics</td>
                                                                  <td>_utmv</td>
                                                                  <td>This is used for reporting in Google Analytics classifying the visitor.</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>Google Analytics</td>
                                                                  <td>_utmz</td>
                                                                  <td>This is set by Google Maps when you load a map of our location.</td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                            <p>Please note that third parties (including for example, advertising networks and providers of external services) may also use cookies over which we have no control. Any such cookies are likely to be analytical, performance or targeting cookies.</p>
                                                          </li>

                                                        </ol>
                                                      </li>
                                                    </ol>
                                                  </li>

                                                  <li><strong>BLOCKING COOKIES</strong>
                                                    <ol>
                                                      <li><strong>By using our Site, you are consenting to our use of these non-essential cookies. If you do not consent to our using non-essential cookies you may opt to block the cookies by using the appropriate setting on your browser. For more information on how to disable cookies please see: <a href="www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</a></strong></li>
                                                      <li>Please note that blocking cookies could affect some of the services provided on our Site.</li>
                                                    </ol>
                                                  </li>

                                                  <li><strong>CHANGES TO OUR PRIVACY POLICY</strong>
                                                    <ol>
                                                      <li>Any changes we may make to our cookies policy in the future will be posted on this page.</li>
                                                    </ol>
                                                  </li>

                                                  <li><strong>CONTACT</strong>
                                                    <ol>
                                                      <li>Questions, comments and requests regarding this cookies policy are welcomed and should be addressed to <span className="link-view">info@auracommodities.com</span></li>
                                                    </ol>
                                                  </li>
                                                </ol>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        );
    }
}

export default CookiePolicy;
