import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';

import { clientImageNames } from 'constants/ClientImagesUrls';

const Clients = () => {
    return (
        <Carousel
            className='clients-carousel'
            showArrows={false}
            showStatus={false}
            autoPlay={true}
            showIndicators={true}
            showThumbs={false}
            interval={10000}
            infiniteLoop={true}
        >
        {clientImageNames.map(url =>
                <div key={url}
                        className="carousel-div-client">
                    <img alt="" src={window.StaticUrl + "img/clients/" + url} />
                </div> )}
        </Carousel>
    );
}

export default Clients;
