import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import CommodityImageDisplayer from 'util/CommodityImageDisplayer'
import ClientImageDisplayer from 'util/ClientImageDisplayer'
import Clients from 'components/Clients/clients';

//Import Particles
import Particles from 'react-particles-js';

//Importing Modal
import ModalSection from 'components/common/ModalSection';

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    }
    this.callModal.bind(this)
  }

  callModal = () => {
    this.refs.child.openModal();
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-home height-100vh" id="home" style={{ backgroundImage: "url('" + window.StaticUrl + "aura-landing/img/worldmap-markers.png')" }}>
          <div className="bg-overlay">
            <Particles
              height={1080}
              params={{
                "fps_limit": 28,
                "particles": {
                  "number": {
                    "value": 130
                  },
                  "size": {
                    "value": 2
                  },
                  "move": {
                    "speed": 1.9
                  },
                  "opacity": {
                    "anim": {
                      "enable": true,
                      "speed": 1,
                      "opacity_min": 0.05
                    }
                  },
                  "line_linked": {
                    "enable": true,
                    "opacity": 0.3
                  }
                },
                "interactivity": {
                  "events": {
                    "onhover": {
                      "enable": true,
                      "mode": "repulse"
                    }
                  }
                },
                "move": {
                  "radius": 3,
                },
                "retina_detect": true
              }}
            />
          </div>
          <div className="">
            <div className="">
              <Container className="slidero">
                <Row>
                  <Col className="text-white text-center">
                    <h4 className="home-small-title">Welcome to your</h4>
                    <h1 className="home-title">Smart Commodity Assistant</h1>
                    <p className="padding-t-10 home-desc mx-auto">Powerful data visualisation platform</p>
                    <p className="padding-t-10 home-desc mx-auto">Spot weather disruptions at inception</p>
                    <p className="padding-t-10 home-desc mx-auto">Observe supply & demand report data at publication</p>
                    <div className="play-shadow margin-t-10 margin-l-r-auto">
                      <CommodityImageDisplayer />
                    </div>
                    <div className="show-only-large-screens">
                      <div className="margin-t-60">
                        <h3 className="home-small-title">Some of Our Clients</h3>
                        <ClientImageDisplayer />
                      </div>
                    </div>
                    <div className="show-only-small-screens">
                      <div style={{ 'marginTop': '10%' }}>
                        <h3 className="home-small-title">Some of Our Clients</h3>
                        <Clients />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              {/* Render ModalSection Component for Modal */}
              <ModalSection ref="child" channel='vimeo' videoId='99025203' />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
