import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Label, Alert } from "reactstrap";
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import DialCodeOptions from 'constants/DialCodeOptions'
import EmployeePositionOptions from 'constants/EmployeePositionOptions'
import UserSignupSources from 'constants/UserSignupSources'
import { CountryDropdown } from 'react-country-region-selector';
import { signup, check_email_domain } from 'util/APICall'
import _debounce from 'lodash.debounce'
import { extractEmailDomain } from 'util/HelperFunctions'
import { MixpanelActions } from 'util/Mixpanel';
import _ from 'lodash'
//Import Home Button
import AccountHomeButton from "./account-home-button";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      country: "",
      loading: false,
      success: false,
      section: 1,
      errors: [],
      values: null,
      userCommodities: []
    };
  }

  componentWillMount() {
    document.body.classList.add("bg-account-pages");
    document.body.classList.add("py-4");
    document.body.classList.add("py-sm-0");
  }

  onClickSignup = (user) => {
    this.setState({ loading: true })
    signup(user).then(response => {
      const userId = _.get(response.data, 'data.user.id', null)
      const profileId = _.get(response.data, 'data.id', null)
      const companyDomain = extractEmailDomain(user.user.email)

      // mixpanel
      MixpanelActions.identify(profileId)
      MixpanelActions.people.set({
        'User ID': userId,
        'Profile ID': profileId,
        'Company Domain': companyDomain
      })
      MixpanelActions.track('Signup', { profileId });

      this.setState({
        error: false,
        errorMessage: null,
        loading: false,
        success: true
      })
    }).catch(err => {
      let errMsg = ""
      if (err && err !== null && err.response && err.response.data !== null
        && err.response.data.errors !== null) {
        Object.entries(err.response.data.errors).forEach(
          ([field, msg]) => {
            if (field === 'user')
              Object.entries(msg).forEach(
                ([sub_field, sub_msg]) =>
                  errMsg += (this.capitalizeText(sub_field) + ": " + sub_msg + "\n")
              )
            else
              errMsg += (this.capitalizeText(field) + ": " + msg + "\n")
          })
      } else {
        errMsg = err.message
      }

      this.setState({ error: true, errorMessage: errMsg, loading: false })
    })
  }

  capitalizeText = text => {
    text = text.replace(/_/, " ")
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  handleFinishSignup = (values) => {
    if (this.state.country == "") {
      this.setState({ error: true, errorMessage: "Please fill in all of the fields to complete your sign up." })
      return
    }

    let user = {
      first_name: values['firstname'],
      last_name: values['lastname'],
      username: values['email'],
      email: values['email'],
    }

    let profile = {
      telephone_dial_code: values['telephone_dial_code'].replace(' ', ''),
      telephone_number: values['telephone_number']
    }

    profile.company_name = values['company']
    profile.position = values['position']
    profile.country = this.state.country
    profile.user_source = values['user_source']

    profile.user = user
    this.onClickSignup(profile)
  }

  handleSubmit = (event, errors, values) => {
    let newErrors = [...errors]

    if (this.state.country == '')
      newErrors.push('country')

    this.setState({
      errors: newErrors
    })

    if (errors.length == 0) {
      this.handleFinishSignup(values);
    }
  }

  debouncedClickSubmitHandler = _.debounce(this.handleSubmit, 750)

  // debounce to not pound the 'server'
  validate = _debounce((value, ctx, input, cb) => {
    // cancel pending 'network call'
    clearTimeout(this.timeout);

    // simulate network call
    this.timeout = setTimeout(() => {
      cb(value === 'valid' || value === '');
    }, 500);

  }, 500);

  // debounce to not pound the 'server'
  validate = _debounce((value, ctx, input, cb) => {
    // Check if empty
    if (ctx.email === '') {
      cb(true)
      // check if matches email address format
    } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(ctx.email)) {
      // send request to backend to confirm domain validity
      check_email_domain(ctx.email.split('@')[1])
        .then(resp => resp.data ? cb(resp.data.data) : cb(''))
        .catch(err => cb(true))
    } else {
      cb(false)
    }
  }, 300)

  updateCountryDropDown = (val) => {
    let newErrors = [...this.state.errors]
    if (newErrors.indexOf('country') > -1)
      newErrors = newErrors.filter(elem => elem != 'country')

    this.setState({
      country: val,
      errors: newErrors
    })
  }

  render() {
    return (
      <React.Fragment>

        <AccountHomeButton />
        <section className="height-100vh">
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Card className="account-card signup-card">
                      <CardBody>
                        <div className="text-center mt-3">
                          <h3 className="font-weight-bold"><a data-testid="link_logo_go_to_landing" href="/" className="text-dark text-uppercase account-pages-logo"><img src={window.StaticUrl + "aura-landing/img/logo.png"} alt="" /></a></h3>
                        </div>
                        {this.state.success == true
                          ?
                          <Alert data-testid="success_msg"
                            color="success">
                            Thank you for your request. Our team will contact you shortly.
                          </Alert>
                          :
                          <div className="p-3 signup-form-wrapper" data-testid="signup_form">
                            <div>
                              <h2 className="signup-title">Tell us about yourself</h2>
                            </div>

                            <AvForm onSubmit={this.debouncedClickSubmitHandler} data-testid="step_one_signup">

                              <Row className="justify-content-center">
                                <Col lg="6">
                                  <AvGroup className="form-group">
                                    <Label data-testid="first_name_label" for="firstname">First Name</Label>
                                    <AvField data-testid="first_name_field" type="text" className="form-group" name="firstname" id="firstname" placeholder="First Name" required
                                      errorMessage=""
                                      validate={{
                                        required: { value: true, errorMessage: "Please enter your first name" },
                                      }}
                                    />
                                  </AvGroup>
                                </Col>
                                <Col lg="6">
                                  <AvGroup className="form-group">
                                    <Label data-testid="last_name_label" for="lastname">Last Name</Label>
                                    <AvField data-testid="last_name_field" type="text" className="form-group" name="lastname" id="lastname" placeholder="Last Name" required
                                      errorMessage=""
                                      validate={{
                                        required: { value: true, errorMessage: "Please enter your last name" },
                                      }}
                                    />
                                  </AvGroup>
                                </Col>
                              </Row>

                              <Row className="justify-content-center">
                                <Col lg="6">
                                  <AvGroup className="form-group">
                                    <Label data-testid="email_label" for="email">Business Email</Label>
                                    <AvField data-testid="email_field" type="email" className="form-group mt-2" name="email" id="email" placeholder="Business Email" required
                                      errorMessage="Please enter a valid business email"
                                      validate={{ async: this.validate }} />
                                  </AvGroup>
                                </Col>
                                <Col lg="6">
                                  <AvGroup className="form-group">
                                    <div className="phone-number-wrapper">
                                      <AvField data-testid="dial_code_field" type="select" name="telephone_dial_code"
                                        className="standard-select dial-code-field"
                                        required
                                        errorMessage=""
                                        validate={{
                                          required: { value: true, errorMessage: "Please enter the dial code for your phone number" },
                                        }}
                                        value={"+1"}
                                      >
                                        {
                                          DialCodeOptions.map((item) => (
                                            <option data-testid="dial_code_select_option"
                                              key={item['value'] + item['label']}
                                              value={item['value']}>{item['label']}</option>
                                          ))
                                        }
                                      </AvField>
                                      <AvField data-testid="phone_number_field" label="Phone Number" type="text" className="phone-number-field" name="telephone_number" id="telephone_number" placeholder="Phone number" required
                                        errorMessage=""
                                        validate={{
                                          required: { value: true, errorMessage: "Please enter your phone number" },
                                        }}
                                      />
                                    </div>
                                  </AvGroup>
                                </Col>
                              </Row>


                              <Row className="justify-content-center">
                                <Col lg="6">
                                  <AvGroup className="form-group">
                                    <div data-testid="country_field_container"
                                      className={this.state.errors.indexOf('country') > -1 ? 'text-danger dropdown-invalid' : ''}>
                                      <Label data-testid="country_label" for="country">Country</Label>
                                      <CountryDropdown
                                        data-testid="country_field"
                                        value={this.state.country}
                                        onChange={this.updateCountryDropDown}
                                        defaultOptionLabel={"Select country"}
                                        classes={"form-control " + (this.state.errors.indexOf('country') > -1 ? 'standard-select is-invalid' : 'standard-select')}
                                        priorityOptions={[
                                          "US", "CA", "NL", "ZA", "GB", "IE", "FR", "BE", "DE", "AT", "CH", "BR", "SG"
                                        ]}
                                      />
                                      {this.state.errors.indexOf('country') > -1 &&
                                        <div className="invalid-feedback">Please select a country</div>}
                                    </div>
                                  </AvGroup>
                                </Col>
                                <Col lg="6">
                                  <AvGroup className="form-group">
                                    <AvField type="text" className="form-group mt-2 mb-0"
                                      label="Company"
                                      name="company"
                                      id="company"
                                      placeholder="Company" required
                                      data-testid="company_field"
                                      errorMessage=""
                                      validate={{
                                        required: { value: true, errorMessage: "Please enter your company name" },
                                      }}
                                    />
                                  </AvGroup>
                                </Col>
                              </Row>

                              <Row className="justify-content-center">
                                <Col lg="6">
                                  <AvGroup className="form-group">
                                    <AvField type="select" name="position" label="Position"
                                      className="standard-select"
                                      data-testid="position_field"
                                      required
                                      errorMessage=""
                                      validate={{
                                        required: { value: true, errorMessage: "Please enter your position" }
                                      }}
                                      value="Procurement / Purchasing manager"
                                    >
                                      {
                                        EmployeePositionOptions.map((item) => (
                                          <option key={item['value']}
                                            data-testid={"position " + item['label']}
                                            value={item['value']}>{item['label']}</option>
                                        ))
                                      }
                                    </AvField>
                                  </AvGroup>
                                </Col>
                                <Col lg="6">
                                  <AvGroup className="form-group">
                                    <AvField type="select" name="user_source" label="How did you hear about us?"
                                      className="standard-select"
                                      data-testid="user_source_field"
                                      required
                                      errorMessage=""
                                      validate={{
                                        required: { value: true, errorMessage: "Please select an option" }
                                      }}
                                      value={null}>
                                      {UserSignupSources.map(
                                        source => <option key={source}
                                          data-testid={source}
                                          value={source == 'Please select an option'
                                            ? "" : source}>{source}</option>
                                      )}
                                    </AvField>
                                  </AvGroup>
                                </Col>
                              </Row>

                              <Row className="justify-content-center">
                                <Col lg="12">

                                  <AvCheckboxGroup className="t-and-c-wrapper"
                                    data-testid="terms_and_cond_container" inline name="terms"
                                    validate={{
                                      required: { errorMessage: 'You must agree with the Terms and Conditions of use' }
                                    }}>
                                    <AvCheckbox data-testid="terms_and_cond_field" value="termsofuse" />
                                    <Label data-testid="terms_and_cond_label" className="invalid-checkbox-label" for="termsofuse">I have read and agree to Aura Commodities <Link data-testid="terms_link" target="_blank" to="/terms-of-use" className="text-danger">Terms of Use</Link> and <Link data-testid="privacy_link" target="_blank" to="/privacy-policy" className="text-danger">Privacy Policy</Link></Label>
                                  </AvCheckboxGroup>

                                  {this.state.error == true && <div style={{ whiteSpace: 'pre-wrap' }}>
                                    <Alert color="danger">
                                      {this.state.errorMessage}
                                    </Alert>
                                  </div>
                                  }

                                </Col>
                              </Row>

                              <Row className="justify-content-center">
                                <Col lg="3">
                                  <Row>
                                    <Col lg="12">
                                      <div className="mt-3">
                                        <Button data-testid="final_submit" type="submit" className="btn btn-custom btn-block submit-button submit-button-border">Submit</Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="mt-4 mb-0 text-center">
                                    <p data-testid="go_to_login_container"
                                      className="mb-0">Already have an account? <Link data-testid="go_to_login" to="/login" className="text-danger">Login</Link></p>
                                  </div>
                                </Col>
                              </Row>

                            </AvForm>
                          </div>
                        }

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default SignUp;
