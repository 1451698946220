import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';

class Meteo extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="meteo">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="5" className="order-2 order-lg-1">
                                <div className="features-box mt-5 mt-lg-0 left-content-col">
                                    <h3>Agro-Meteorological Reports</h3>
                                    <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                                        <li className="">Cutting edge analytics to quantify risks</li>
                                        <li className="">Global weather model interpretation</li>
                                        <li className="">Remote sensing</li>
                                        <li className="">Water (i.e. storage & river levels)</li>
                                        <li className="">Contextual supply & demand crop data</li>
                                        <li className="">Agro-Meteorological models mapping</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={{size: 7, order: 2, }} xs={{order : 1}}>
                                <div className="features-img mx-auto mr-lg-0">
                                    <Carousel
                                      showArrows={false}
                                      showStatus={false}
                                      autoPlay={true}
                                      showIndicators={true}
                                      showThumbs={false}
                                      interval={10000}
                                      infiniteLoop={true}
                                    >
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '350px' }} src={window.StaticUrl + "aura-landing/img/meteoreports/1.png"} />
                                        </div>
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '350px' }} src={window.StaticUrl + "aura-landing/img/meteoreports/2.png"} />
                                        </div>
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '350px' }} src={window.StaticUrl + "aura-landing/img/meteoreports/3.png"} />
                                        </div>
                                    </Carousel>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Meteo;
