import Index9 from "./pages/Index9/Index9";
import Login from "./pages/Auth/login";
import SignUp from "./pages/Auth/signup";
import Terms from "./pages/Auth/terms";
import Privacy from "./pages/Auth/privacy";
import CookiePolicy from "./pages/Auth/cookiepolicy";
import PasswordForget from "./pages/Auth/password_forget";

const routes = [
  //Auth
  { path: "/signup", component: SignUp },
  { path: "/login", component: Login },
  { path: "/terms-of-use", component: Terms },
  { path: "/privacy-policy", component: Privacy },
  { path: "/cookie-policy", component: CookiePolicy },
  { path: "/password_forget", component: PasswordForget },
  { path: "/", component: Index9 },
];

export default routes;
