import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';

class Yield extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section" id="yield">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="5" className="order-2 order-lg-2">
                                <div className="features-box mt-5 mt-lg-0 right-content-col">
                                    <h3>Aura Yield Forecast</h3>
                                    <p className="text-muted web-desc">Aura produces bespoke yield, production and acreage forecasts for over 100 unique regions globally for 8 commodities</p>
                                </div>
                            </Col>
                            <Col lg={{size: 7, order: 1, }} xs={{order : 1}}>
                                <div className="features-img mx-auto mr-lg-0">
                                    <Carousel
                                      showArrows={false}
                                      showStatus={false}
                                      autoPlay={false}
                                      showIndicators={false}
                                      showThumbs={false}
                                      interval={10000}
                                      infiniteLoop={true}
                                    >
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '370px' }} src={window.StaticUrl + "aura-landing/img/yield/1.png"} />
                                        </div>
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '370px' }} src={window.StaticUrl + "aura-landing/img/yield/1.png"} />
                                        </div>
                                    </Carousel>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Yield;
