import React, { Component } from 'react';
import NavbarPage from "components/Navbar/NavbarPage";
import Section from "./section";

import Platform from 'components/Features/platform';
import Meteo from 'components/Features/meteo';
import OnDemand from 'components/Features/ondemand';
import SupplyDemand from 'components/Features/supplydemand';
import Yield from 'components/Features/yield';
import WeatherAlerts from 'components/Features/weather_alerts';

import Services from 'components/Services/services';
import GetStarted from 'components/Get Started/get-started';
import ContactUs from 'components/Contact Us/contact-us';
import Footer from 'components/Footer/footer';
import ChatBotWidget from 'components/intercom/ChatBotWidget';
import IEMessagePopup from 'util/CustomModalPopup';
import CookiePopup from 'components/cookie/CookiePopup';
import CaseStudies from 'components/CaseStudies/casestudies';

const { detect } = require('detect-browser');
const browser = detect();

class Index9 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          navClass : "",
          showCookiePopup: window.localStorage.getItem('cookie_accept') || "true",
          showIEMessage: false,
          showBody: true,
          showTermsConditionPanel: false,
          showPrivacyPolicyPanel: false,
          showCookiePolicyPanel: false,
          showRequestDemoPanel: false,
        };
    }

     componentDidMount()
    {
    }

    handleCloseButtonClick = () => {
        this.setState({ showCookiePopup: false, showIEMessage: !this.state.showIEMessage })
    }

    handleCookieAccepButtonClick = () => {
        this.setState({ showCookiePopup: "false" })
        window.localStorage.setItem('cookie_accept', 'false')
    }

    render() {
        return (
            <React.Fragment>

                {/* Importing Navbar */}
                <NavbarPage history={this.props.history} navClass={this.state.navClass} />
                <Section/>
                <CaseStudies/>
                <Platform/>
                <Services/>
                <Yield/>
                <Meteo/>
                <SupplyDemand/>
                <OnDemand/>
                <GetStarted/>
                <ContactUs/>
                <Footer/>

                <div className="intercom">
                    {this.state.showCookiePopup === "true" && <CookiePopup onClickAccept={this.handleCookieAccepButtonClick} onClickPrivacyPlicy={() => { this.props.history.push("/cookie-policy") }} />}
                    <ChatBotWidget />
                </div>
                <IEMessagePopup isOpen={this.state.showIEMessage}
                    message={"AURA Commodities does not support Internet Explorer (IE). Please use any other browser: Chrome, Edge, Firefox, Safari, etc."}
                    handleCloseButtonClick={this.handleCloseButtonClick}
                />

            </React.Fragment>
        );
    }
}

export default Index9;
