import mixpanel from 'mixpanel-browser';
import { extractEmailDomain } from 'util/HelperFunctions'
import { logError } from './Logging';

const debug = ['development', 'testing', 'staging'].includes(process.env.REACT_APP_ENV)

mixpanel.init(
    process.env.REACT_APP_MIXPANEL_PROJ_TOKEN,
    { debug }
);

let isActive = ['production', 'development', 'staging'].includes(process.env.REACT_APP_ENV);

let actions = {
    identify: (id) => {
        try {
            // we ignore the scripts account
            if (id == 441) {
                mixpanel.disable()
                return
            }

            if (isActive) mixpanel.identify(id);
        }
        catch (err) {
            logError(err, 'Mixpanel identify')
        }
    },
    alias: (id) => {
        try {
            // we ignore the scripts account
            if (id == 441) {
                mixpanel.disable()
                return
            }

            if (isActive) mixpanel.alias(id);
        }
        catch (err) {
            logError(err, 'Mixpanel alias')
        }
    },
    track: (name, props) => {
        try {
            if (isActive) mixpanel.track(name, props);
        }
        catch (err) {
            logError(err, 'Mixpanel track')
        }
    },
    loginEvent: (userId, profileId, userEmail) => {
        try {
            // we ignore the scripts account
            if (userEmail === 'script@auracommodities.com') {
                mixpanel.disable()
                return
            }

            // get the company name
            const companyDomain = extractEmailDomain(userEmail)

            // mixpanel specific actions
            mixpanel.identify(profileId)
            mixpanel.people.set({
                'User ID': userId,
                'Profile ID': profileId,
                'Company Domain': companyDomain,
                "Last logged in": new Date().toISOString()
            })
            mixpanel.track('Login', { profileId });
        }
        catch (err) {
            logError(err, 'Mixpanel loginEvent')
        }
    },
    people: {
        set: (props) => {
            try {
                // we ignore the scripts account
                if (props['Profile ID'] == 441) {
                    mixpanel.disable()
                    return
                }

                if (isActive) mixpanel.people.set(props);
            }
            catch (err) {
                logError(err, 'Mixpanel people.set')
            }
        },
    },
}

export const MixpanelActions = actions;
