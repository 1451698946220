import React from 'react'

const commodityImageNames = [
  "Wheat",
  "Barley",
  "Corn",
  "Soybeans",
  "Palm Oil",
  "Rapeseed",
  "Sunflower Seed",
  "Sugar",
  "Cocoa",
  "Coffee",
  "Cotton"
]

const CommodityImageDisplayer = () => {
    return <div className="commodity-displayer-container">
        {commodityImageNames.map((name, index) => {
            return <div key={index} className="commodity-item">
                <img src={window.StaticUrl + "img/commodity/" + name + ".png"}
                     className="commodity-img" />
                <span>{name}</span>
            </div>
        })}
    </div>

}

export default CommodityImageDisplayer
