import React, { Component } from 'react';
import { Container, Row } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';
import SectionTitle from "../common/section-title";
import CaseStudyBox from "./casestudy-box";


class CaseStudies extends Component {
    state = {
        casestudies : [
            {
              id: 1,
              image: window.StaticUrl + "aura-landing/img/casestudies/1.png",
              title: "USA Corn 22/23MY",
              points: [
                "In December 2021, Aura cautioned the potential risk of La Niña and its high-pressure impact on summer weather and the 22/23 MY crop yield.",
                "Our national yield estimate for corn was 171 bu/ac by February 8th, 2022.",
                "On April 5th, 2022, Aura raised the alert to MEDIUM as the soil moisture conditions deteriorated across key western regions.",
              ],
            },
            {
              id: 2,
              image: window.StaticUrl + "aura-landing/img/casestudies/2.png",
              title: "EU Corn 22/23MY",
              points: [
                "On July 4th, 2022, Aura raised the alert level to HIGH as dry and hot conditions were set to persist through July and August. These conditions were considered worse than the heatwave and drought during the summers of 2003, 2018, and 2020.",
                "Aura’s July production estimate: -6MMT",
                "USDA/EUROSTAT. July production estimate: -10MMT",
              ],
            },
            {
              id: 3,
              image: window.StaticUrl + "aura-landing/img/casestudies/3.png",
              title: "Brazil Soybean 21/22MY",
              points: [
                "On August 6th, 2021, Aura raised the dryness risk associated with La Niña and Brazil's soy production.",
                "December 3rd, 2021, Aura raised the alert level to MEDIUM before drier weather had set in. On January 5th, 2022, the level was changed to HIGH as dryness was realized.",
                "By the end of January 2022, Aura’s production estimate was 9MMT lower than the USDA heading into the February WASDE report.",
              ],
            },
            {
              id: 4,
              image: window.StaticUrl + "aura-landing/img/casestudies/4.png",
              title: "Canada Wheat 21/22MY",
              points: [
                "Aura’s best estimate was 7.5MMT lower than the USDA heading into the August WASDE.",
                "By the start of August, the NDVI Anomaly highlighted poor vegetative health in Canada (as demonstrated below.) On April 8th, 2021, Aura highlighted a dry potential from spring into summer.",
                "Aura raised the alert level to HIGH on July 12th, 2021, and production estimates were lowered as heat combined with dry soil impacted crop development.",
              ],
            },
            {
              id : 5,
              image : window.StaticUrl + "aura-landing/img/casestudies/5.png",
              title : "USA Corn 20/21MY",
              points : [
                "Aura alerts in August 2020 flagged a large revision in corn production due to a Derecho event and droughts in Iowa.",
                "Our Production Estimate was 20MMT lower than the USDA in the second week of August 2020. Our analysis included satellite and drought imagery across Iowa (examples shown).",
                "USDA estimates didn’t fall into line with Aura until late 2020",
              ],
            },
            {
              id : 6,
              image : window.StaticUrl + "aura-landing/img/casestudies/6.png",
              title : "Brazil Corn 20/21MY",
              points : [
                "Aura’s first Weather Alert and Production Estimates were issued in August 2020 when the alert level was HIGH. Aura’s estimates were consistently lower than the USDA and CONAB through the season. Our initial Production Estimate flagged a risk of <100MMT for corn.",
                "Delays in soybean because of dry and cold weather lead to a challenging season across Brazil including Parana (shown on the right). Aura issued a timely frost analysis to identify area risk from damage in June and July of 2021.",
                "CBOT corn rallied in early May due to the weather impacts on Brazil corn.",
              ],
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="casestudies">
                    <Container>

                        {/* Render section title */}
                        <SectionTitle
                          title="Case Studies"
                          description=""
                        />
                        <Row>
                            {/* Render blog boxes */}
                            <Carousel
                              showArrows={false}
                              showStatus={false}
                              autoPlay={true}
                              showIndicators={true}
                              showThumbs={false}
                              interval={10000}
                              infiniteLoop={true}
                              swipeScrollTolerance={5}
                              swipeable={true}
                              emulateTouch={true}
                            >
                              {
                                  this.state.casestudies.map((casestudy, index) =>
                                      <CaseStudyBox key={index} casestudy={casestudy}/>
                                  )
                              }
                            </Carousel>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default CaseStudies;
