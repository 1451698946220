import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";

class OnDemand extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="ondemand">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="5" className="order-2 order-lg-1">
                                <div className="features-box mt-5 mt-lg-0 left-content-col">
                                    <h3>Meteorologist On Demand</h3>
                                    <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                                        <li className="">Bookable next day video conference consultations</li>
                                        <li className="">Establish rapport with our expert weather team</li>
                                        <li className="">Global weather model forecast interpretation</li>
                                        <li className="">Know about weather / crop risk first</li>
                                        <li className="">Historical weather impacts context on commodities</li>
                                        <li className="">Analysis of Aura yield disruptions risk level</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={{size: 7, order: 2, }} xs={{order : 1}}>
                                <div className="features-img mx-auto mr-lg-0">
                                    <div className="carousel-div">
                                      <img className="ondemand-image" src={window.StaticUrl + "aura-landing/img/slides/ondemand/1.png"} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default OnDemand;
