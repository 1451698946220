import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class FooterLinks extends Component {
    state = {
        socials : [
            { icon : "mdi mdi-twitter", link : "https://twitter.com/AuraCommodities/" },
            { icon : "mdi mdi-linkedin", link : "https://www.linkedin.com/company/aura-commodities/" },
            { icon : "mdi mdi-instagram", link : "https://www.instagram.com/auracommodities/" },
        ],
        footerItems : [
            { title : "Aura Commodities" ,
                links : [
                    { linkTitle : "Terms and Conditions", link : "/terms-of-use" },
                    { linkTitle : "Privacy Policy", link : "/privacy-policy" },
                    { linkTitle : "Cookie Policy", link : "/cookie-policy" },
                ]
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <div className="footer-alt">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="float-left pull-none">

                                    {
                                        this.state.footerItems.map((item, i) =>
                                          <ul key={i}
                                              className="list-unstyled footer-list">
                                              <p className="copy-rights text-white mb-3 mb-sm-0">© { new Date().getFullYear() } Aura Commodities Ltd. All Rights reserved.</p>
                                              {
                                                  item.links.map((link) =>
                                                      <li key={link.linkTitle}><Link to={link.link}>{link.linkTitle}</Link></li>
                                                  )
                                              }
                                          </ul>
                                        )
                                    }
                                </div>
                                <div className="float-right pull-none ">
                                    <ul className="list-inline social m-0">
                                        {
                                            this.state.socials.map((social) =>
                                                <li key={social.icon}
                                                    className="list-inline-item">
                                                    <a target="_blank" 
                                                       href={social.link} 
                                                       className="social-icon">
                                                        <i className={social.icon}/>
                                                    </a>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                <div className="clearfix"></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FooterLinks;
