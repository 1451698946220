import React from 'react'
import Modal from 'react-modal';

const customModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '20px',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxWidth: '600px',
        height: 'auto',
        background: '#0B6298',
        color: '#fff'
    }
};

const CustomModalPopup = ({ message, handleCloseButtonClick, closeButtonText, isOpen, modalStyle }) => {
    return <Modal isOpen={isOpen} style={modalStyle || customModalStyle}>
        <h6>{message}</h6>
        <div className="button-panel" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <button type="button" className="btn btn-outline-primary" onClick={() => handleCloseButtonClick()}>{closeButtonText || "Close"}</button>
        </div>
    </Modal>
}

export default CustomModalPopup