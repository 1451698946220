import React, { Component } from "react";
import routes from "./routes";
import {
    withRouter,
    Route,
    Switch,
    HashRouter as Router
} from "react-router-dom";

import { MixpanelActions } from './util/Mixpanel'
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        {routes.map((route, idx) => (
                            <Route path={route.path} component={route.component} key={idx} />
                        ))}
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}

export default withRouter(App);
