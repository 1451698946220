import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';

class SupplyDemand extends Component {
    render() {
        return (
          <React.Fragment>
              <section className="section" id="yield">
                  <Container>
                      <Row className="align-items-center">
                          <Col lg="5" className="order-2 order-lg-2">
                              <div className="features-box mt-5 mt-lg-0 right-content-col" >
                                  <h3>Supply and Demand Data</h3>
                                  <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                                      <li className="">Agricultural reports from recognized global institutions</li>
                                      <li className="">Low latency from report release to platform publication</li>
                                      <li className="">Ability to download key report data</li>
                                      <li className="">Updated global crop calendar per commodity</li>
                                      <li className="">Satellite derived crop locations</li>
                                      <li className="">Global country overview dashboard per commodity</li>
                                  </ul>
                              </div>
                          </Col>
                          <Col lg={{size: 7, order: 1, }} xs={{order : 1}}>
                              <div className="features-img mx-auto mr-lg-0">
                                  <Carousel
                                    showArrows={false}
                                    showStatus={false}
                                    autoPlay={true}
                                    showIndicators={true}
                                    showThumbs={false}
                                    interval={10000}
                                    infiniteLoop={true}
                                  >
                                      <div className="carousel-div">
                                        <img style={{ width: 'auto', maxHeight: '370px' }} src={window.StaticUrl + "aura-landing/img/sd/1.png"} />
                                      </div>
                                      <div className="carousel-div">
                                        <img style={{ width: 'auto', maxHeight: '370px' }} src={window.StaticUrl + "aura-landing/img/sd/2.png"} />
                                      </div>
                                      <div className="carousel-div">
                                        <img style={{ width: 'auto', maxHeight: '370px' }} src={window.StaticUrl + "aura-landing/img/sd/3.png"} />
                                      </div>
                                      <div className="carousel-div">
                                        <img style={{ width: 'auto', maxHeight: '370px' }} src={window.StaticUrl + "aura-landing/img/sd/4.png"} />
                                      </div>
                                  </Carousel>
                              </div>
                          </Col>
                      </Row>
                  </Container>
              </section>
          </React.Fragment>
        );
    }
}

export default SupplyDemand;
