import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, Alert } from "reactstrap";

//Import Home Button
import AccountHomeButton from "./account-home-button";

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
          error: false,
          errorMessage: ""
        };
    }

    componentDidMount() {
      window.scrollTo(0, 0);
    }

    componentWillMount() {
        document.body.classList.add("bg-account-pages");
        document.body.classList.add("py-4");
        document.body.classList.add("py-sm-0");
    }

    render() {
        return (
            <React.Fragment>

                {/* render home button */}
                <AccountHomeButton/>

                <section className="height-100vh">
                    <div className="display-table">
                        <div className="display-table-cell">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col lg="12">
                                        <Card className="account-card">
                                            <CardBody>
                                                <div className="text-center mt-3">
                                                    <h3 className="font-weight-bold"><a href="/" className="text-dark text-uppercase account-pages-logo"><img src={window.StaticUrl + "aura-landing/img/logo.png"} alt="" /></a></h3>
                                                    <p className="text-muted">Terms and Conditions</p>
                                                </div>
                                                <div className="p-3">
                                                    <ol>
                                                        <li><strong>INTRODUCTION</strong>
                                                            <ol>
                                                                <li>Aura Commodities Limited <strong>(we, us, our)</strong> is a limited liability company incorporated in England and Wales with company number 10088267 and with registered office at 17 Hanover Square, Mayfair, London, England, W1S 1BN.</li>
                                                                <li>These terms of use (Terms) govern the use by you of the website located at <a href="https://auracommodities.com/" target="_blank">https://auracommodities.com/</a> (such website, the Site). The Site comprises two separate areas:
                                                                    <ol>
                                                                        <li>the software application known by us as the Aura Commodities Core Product containing third party content obtained from public and private sources as well as content produced by us (such application and content, the <strong>Product</strong>). The Product may only be accessed by approved trial users and by account holders with an active and properly paid-up subscription (each such type of user an <strong>open account holder</strong>); and</li>
                                                                        <li>web pages which are accessible by non-account holders, or account holders without an active subscription <strong>(dormant account holders)</strong>, and which contain information about and extracts from the Product together with such other information as we may deem useful from time to time (these web pages, the <strong>External Site</strong>).</li>
                                                                    </ol>
                                                                </li>
                                                                <li>You may not use the Site if you do not agree to these Terms. By using the Site, you will be deemed to have accepted the Terms.  The Terms constitute a binding legal agreement between you and us.</li>
                                                                <li>You agree that we may modify the Terms without notice to you as and when we determine.  We will post any changes to the Terms on the Site and you should check for any changes on subsequent visits. Any use by you of the Site will be deemed acceptance of the Terms in force at that time.</li>
                                                                <li>References to Clauses are references to the Clauses of these Terms.  Words in the singular include the plural and in the plural include the singular. Use of any gender includes all other genders. A reference to a particular law is a reference to it as it is in force for the time being taking account of any amendment, extension, application or re-enactment and includes any subordinate legislation for the time being in force made under it.  References to including and include(s) shall be deemed to mean respectively <strong>including without limitation and include(s) without limitation.</strong></li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>GRANT OF LICENCE</strong>
                                                            <ol>
                                                                <li>Aura Commodities grants you a non-exclusive, non-transferable, non-sublicensable, non-assignable licence to use:
                                                                    <ol>
                                                                        <li>the External Site for your information or educational use; and</li>
                                                                        <li>(only if you are an open account holder) the Product in the ordinary course of your business</li>
                                                                    </ol>
                                                                    <p>subject at all times to your adherence to these Terms.</p>
                                                                </li>
                                                                <li>In Clause 2.1 use means access via web browser, provided that at all times you must not:
                                                                    <ol>
                                                                        <li>use the Site in breach of any law, and/or decree of any relevant regulatory body;</li>
                                                                        <li>engage in any activity that interferes with or disrupts the Site, or the servers and networks which are connected to the Site (including accessing (or attempting to access), monitoring and/or copying any part of the Site through any automated means, including use of agents, robots, spiders, scripts or web crawlers);</li>
                                                                        <li>copy all or part of the contents of the Site in order to make those contents available to third parties (unless we have given our specific prior written consent);</li>
                                                                        <li>enable and/or permit any third party to access the Site;</li>
                                                                        <li>allow the licence granted at Clause 2.1 to become the subject of any charge, lien or encumbrance, or deal in any other manner with any or all of your rights and obligations under these Terms without our specific prior written consent; </li>
                                                                        <li>forward  via email, printed matter or any other means any Content of the Site to any third party;</li>
                                                                        <li>duplicate, modify or distribute, reverse engineer, create derivative works of, decompile, disassemble, translate, transmit, arrange, modify, copy, bundle, sell, sub-license, export, transfer, loan, rent, lease, assign or adapt any portion of the Site’s software, nor merge or incorporate it with any other software nor permit any third party to do so, except solely to the extent permitted by applicable law;</li>
                                                                        <li>cease to maintain security measures as may be commercially reasonably required to prevent unauthorised access to and use of the Site.</li>
                                                                    </ol>
                                                                </li>
                                                                <li>The licence granted at Clause 2.1 is granted:
                                                                    <ol>
                                                                        <li>in respect of your use of the External Site for so long as you adhere to these Terms; and</li>
                                                                        <li>(where you are an open account holder) in respect of your use of the Platform for the duration of your trial or subscription period, </li>
                                                                    </ol>
                                                                    <p>subject in each case to the provisions of Clause 7. </p>
                                                                </li>
                                                                <li>You agree that it is your responsibility that any passwords and/or login details are kept confidential at all times. As such, you acknowledge and agree that you will be responsible for any activities carried out using your login details. If you know or suspect that someone else knows your password or you suspect a security breach, you agree to notify us by contacting us immediately.</li>
                                                                <li>You agree that we may change the structure, content and nature of the Site at any time without notice to you. In particular, you agree that we may cease providing any or all of the Site to you if we (in our sole discretion) determine that your bandwidth usage exceeds a reasonable level we may control your use of the site by reducing or preventing your access.</li>
                                                                <li>Without prejudice to any other rights or remedies that we may have, you acknowledge and agree that damages alone may not be an adequate remedy for any breach by you of the provisions of this Clause 2, and that accordingly we shall be entitled, without proof of special damages, to the remedies of injunction, specific performance or other equitable relief for any threatened or actual breach of the provisions of this Clause 2.</li>
                                                                <li>You acknowledge that your continued access to the Site is contingent upon compliance with all third party obligations binding upon the operation of the Site (including our hosting facility network provider’s acceptable use policy) (all such obligations, the <strong>AUPs</strong>). In order to enable our continued provision of the Site you agree and acknowledge as reasonable that we may suspend the provision of the Site without telling you and without liability if:
                                                                    <ol>
                                                                        <li>you use the Site in violation of any AUP;</li>
                                                                        <li>you do not cooperate with any investigation of any suspected violation of any AUP;</li>
                                                                        <li>there is an attack on servers or other event for which we reasonably believe that the suspension of the System is necessary to protect you, other users and/or the network; or</li>
                                                                        <li>if required by law or regulation or as compelled by a law enforcement or government agency.</li>
                                                                    </ol>
                                                                </li>
                                                                <li>You acknowledge and agree as reasonable that we may (but are not obliged to) monitor the use of the Site and/or any part of it by and/or on your behalf, including for the purposes of monitoring your compliance with these Terms.</li>
                                                                <li>You undertake to indemnify us and keep us indemnified for any claims, costs, liabilities, losses, and expenses (including reasonable legal expenses) (Losses) incurred in any manner by us as a direct consequence of any breach by you of any of the provisions contained in this Clause 2.  </li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>USER CONTENT</strong>
                                                            <ol>
                                                                <li>If you upload any content to the Site (all content uploaded by you and/or on your behalf, <strong>User Content</strong>), you grant us a non-exclusive, irrevocable and permanent to use the User Content for our business purposes (including the option not to use it, or to use only part, to delete or archive it or to make such changes to such User Content as we in our sole discretion may determine).  You agree that we may transmit or distribute the User Content over various public networks and in various media, as part of the technical process of providing the Site. You also represent and warrant to us that you have all necessary powers, rights and authority to grant the licence contained in this Clause 3. </li>
                                                                <li>You represent and warrant to us that no User Content shall:
                                                                    <ol>
                                                                        <li>contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Site or another's computer;</li>
                                                                        <li>comprise material that encourages conduct that could constitute a criminal offense or give rise to civil liability;</li>
                                                                        <li>comprise material that is unlawful, harmful, threatening, defamatory, obscene, harassing or racially or ethnically offensive, facilitates illegal activity, depicts sexually explicit images, or promotes unlawful violence, discrimination based on race, gender, colour, religious belief, sexual orientation, disability, or any other illegal activities. </li>
                                                                    </ol>
                                                                </li>
                                                                <li>We assume no liability for any User Content, and do not audit the Site for anything that may breach any representation and warranty given by you in this Clause 3. However, if we determine in our sole discretion that any User Content so breaches we may remove it immediately without notifying you, or requiring your consent. </li>
                                                                <li>The Site may contain links that make it easy for you to visit other websites.  We do not have any control over these sites, and we are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from, such sites or resources. </li>
                                                                <li>References to Clauses are references to the Clauses of these Terms.  Words in the singular include the plural and in the plural include the singular. Use of any gender includes all other genders. A reference to a particular law is a reference to it as it is in force for the time being taking account of any amendment, extension, application or re-enactment and includes any subordinate legislation for the time being in force made under it.  References to including and include(s) shall be deemed to mean respectively <strong>including without limitation and include(s) without limitation.</strong></li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>SOCIAL MEDIA</strong>
                                                            <ol>
                                                                <li>For the purposes of this Clause 4, social media shall mean all forms of social media, including LinkedIn, Twitter, Google, Instagram and all other social networking sites, internet postings and blogs (Social Media). </li>
                                                                <li>This Clause applies to use of Social Media for business purposes as well as personal use that may affect our business in any way.</li>
                                                                <li>Any Social Media postings made by us shall be subject to Clause 6 of these Terms. </li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>INTELLECTUAL PROPERTY RIGHTS </strong>
                                                            <ol>
                                                                <li>In these Terms <strong>Intellectual Property Rights</strong> shall mean all intellectual property rights wherever in the world arising, whether registered or unregistered (and including any application), including copyright, database rights, patents, patent applications, patent rights, rights in designs, trademarks, trademark applications, trademark registrations, trademark rights, trade secrets and all other intellectual property and proprietary information rights as may exist now or hereafter come into existence, all modifications, continuations, renewals and extensions of the foregoing, and all claims, actions, causes of action, damages, costs, expenses, profits, penalties, recoveries and remedies relating to any past, present or future infringement of any of the foregoing, arising under the laws of any country, state or jurisdiction in the world.</li>
                                                                <li>The Site contains a range of information (including text, data files, video, audio and graphics and other content) <strong>(Aura Commodities Site Content)</strong> which may be protected by Intellectual Property Rights by virtue of national and international laws.  Aura Commodities Site Content does not include User Content or any part of it. You:
                                                                    <ol>
                                                                        <li>undertake at all times to comply with all such laws as are applicable to you in relation to your use of the Aura Commodities Site Content and the Site;</li>
                                                                        <li>undertake not to use any of the trademarks, trade names, service marks, copyrights, logos, domain names and other distinctive brand features contained in the Aura Commodities Site Content, save in course of the permitted use of the Site by you;</li>
                                                                        <li>agree not to alter, remove or obscure any proprietary rights notices (including copyright and trade mark notices) which may appear in or be held within the Site;</li>
                                                                        <li>acknowledge that all Intellectual Property Rights subsisting in or used in connection with the Site are and shall remain the sole property of us (such Intellectual Property Rights, the <strong>Aura Commodities Site Content Rights</strong>) or, where ownership of the same does not belong to us, of our licensors.</li>
                                                                    </ol>
                                                                </li>
                                                                <li>Each of you and we shall indemnify the other from and against all Losses actually incurred by the indemnified party as a direct result of a claim that (where you are the indemnifying party) the use of User Content by us, or (where we are the indemnifying party) the use of the Aura Commodities Site Content Rights by you, infringes any third party’s Intellectual Property Rights <strong>provided that</strong> the indemnified party promptly notifies the indemnifying party in writing of the claim, allows the indemnifying party control of the defence, and reasonably cooperates with the indemnifying party in the defence at the indemnifying party’s reasonable expense. We shall have no liability under this indemnity for any claim to the extent based on any use of the Site other than in accordance with our instructions.</li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>LIMITATION OF LIABILITY</strong>
                                                            <ol>
                                                                <li>You acknowledge and agree as reasonable that save to the extent expressly stated otherwise in these Terms:
                                                                    <ol>
                                                                        <li>use of the Site or any party of it by you and/or any party on your behalf is at your sole risk;</li>
                                                                        <li>everything contained in the Site (including any Aura Commodities Site Content and/or any software) is provided “as is” and “as available”;</li>
                                                                        <li>we make no warranty or representation of any kind, whether statutory, express or implied in relation to the Site including merchantability or fitness for purpose and/or use. Any condition, warranty, representation or other term concerning the supply of the Site which might otherwise be implied into or incorporated in these Terms, or any collateral contract, whether by statute, common law or otherwise, is hereby excluded to the fullest extent permitted by law;</li>
                                                                        <li>whilst we obtain information in good faith from sources which we consider to be reliable, the contents of the Site may be based on information supplied to us by third parties and are not independently verified by us;</li>
                                                                        <li>we do not guarantee the sequence, accuracy, completeness any/or timeliness of the Site. The Site is not intended to and does not provide strategic, tax, legal or investment advice.  You should seek independent tax, legal and/or investment advice before acting on information obtained from the Site;</li>
                                                                        <li>we give no warranty or representation of any sort in respect of the accuracy of any third party content (including member content) featured on or otherwise incorporated into our Site or in any information provided by us via our chat function, over email or telephone or otherwise. We do not endorse any third party content or data or any opinion, recommendation or advice expressed by a third party on our Site. Where we have included content from third party reports or sources (whether in raw or processed form) we have not taken any steps to ensure the accuracy of such information and accept no liability if such information is inaccurate;</li>
                                                                        <li>we, our subsidiaries and affiliates, and our licensors, do not represent or warrant to you that (a) your use of the Site (including its use in conjunction with any other software) will meet your requirements, (b) your use of the Site will be uninterrupted, timely, secure or error-free, (c) any information obtained by you as a result of your use of the Site will be reliable, or (d) defects in the operation or functionality of the Site, will be corrected, rectified, or remedied; and</li>
                                                                        <li>we shall not be under, and exclude to the fullest extent permitted by law all liability to you for, any liability whatsoever in respect of (a) any mistakes, errors, inaccuracies or omissions in, or incompleteness of, any data held on the Site, (b) delays in updating the Site or non-availability thereof (c) loss of profit, business revenue, goodwill and anticipated savings resulting from your use of the Site; (d)  any and all trading, investment or other losses which you may incur as a result of use of or reliance upon the Site, (e) internet failure, and/or your failure to have in place any necessary software or equipment and/or (f) any information we provide about weather or meteorological conditions. All such information is sourced from third parties and we are not under any circumstances able to confirm such information’s accuracy, timeliness and/or completeness.  </li>
                                                                        <li>in the event of an emergency and/or the need for clarification, you will refer to the appropriate governmental weather organization for further information (for which we take no responsibility as per Clause 6.1.8).</li>
                                                                    </ol>
                                                                </li>
                                                                <li>We exclude liability for any indirect, special, incidental and/or consequential loss and/or damage incurred by you in connection with these Terms. Our total aggregate liability to you shall be limited to a sum equal to all subscription fees, if any, paid by you in relation to the twelve month period during which the event giving rise to your claim occurred.</li>
                                                                <li>Notwithstanding any other provision of these Terms, we do not exclude or limit liability to the extent that the same may not be excluded or limited under governing law.</li>
                                                                <li>You agree and acknowledge that you are in a better position than us to foresee and evaluate any potential damage or loss which you may suffer in connection with the use of the Site; that we cannot adequately insure its potential liability to you; that you are not required to make any payment for the license granted under these Terms, and that accordingly the exclusions and limitations contained in this Clause 6 are reasonable.</li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>TERMINATION</strong>
                                                            <ol>
                                                                <li>We may terminate our relationship with you immediately on giving written notice to you if any of the following occurs:
                                                                    <ol>
                                                                        <li>you commit a breach of any of these Terms which is in our opinion irremediable, or which has not been fully remedied within 14 days of our written notification to you;</li>
                                                                        <li>(where you are a body corporate or a partnership), if you present a petition or has a petition presented by a creditor for your winding up, or convene a meeting to pass a resolution for voluntary winding up, or enter into any liquidation (other than for the purposes of a bona fide reconstruction or amalgamation), or have a receiver or administrator appointed over all or any of your undertaking or assets; (where you are an individual), if you commit any act of bankruptcy or insolvency, or make or negotiate any composition or arrangement for the benefit of your creditors; or</li>
                                                                        <li>where we believe we are required to terminate our relationship with you by law (including where the provision of the Site is, or becomes, unlawful);</li>
                                                                        <li>where the provision of the Site is in our opinion no longer commercially viable.</li>
                                                                    </ol>
                                                                </li>
                                                                <li>Termination shall not affect any accrued rights of either you or us existing as at the date of termination. Clauses 3 (User Content), 4 (Social Media), 5 (Intellectual Property Rights), 6 (Limitation of Liability),  7 (Termination) and 8 (Confidentiality) shall remain in full force and effect notwithstanding termination of these Terms.</li>
                                                                <li>You acknowledge that we shall have the right to disable any password used by you in your use of the Site immediately upon termination of these Terms or where we in our sole discretion and without notice to you believe that the interests of us, of any other user and/or of the Site are jeopardised.</li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>CONFIDENTIALITY</strong>
                                                            <ol>
                                                                <li>In using our Site you are provided access to certain confidential information which is valuable to us. Except as otherwise permitted by these Terms you agree to hold such confidential information in confidence and not make it available to any third party, or use the confidential information otherwise than as set out in these Terms. You will use all reasonable endeavours to procure that any confidential information accessed or held by you is not disclosed or shared with any third party. You shall keep confidential, shall not use for any purpose not permitted in these Terms and shall not disclose any password employed by you in your use of the Site.</li>
                                                                <li>For the purposes of these Terms <strong>confidential information</strong> means information in whatever form or medium disclosed by us to you, including but not limited to commercial or technical know-how, technology, information pertaining to business operations and strategies, information pertaining to clients, pricing and marketing information and whether marked as confidential or being information which ought reasonably to be considered confidential in light of the nature of the information and/or circumstances of its disclosure.</li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>MISCELLANEOUS</strong>
                                                            <ol>
                                                                <li>You shall not assign, transfer or sub-license any of your rights or obligations under these Terms.</li>
                                                                <li>Failure or neglect by us to enforce any of the provisions of these Terms at any time shall not be construed or deemed to be a waiver of our rights nor shall this in any way affect the validity of the whole or any part of these Terms, nor prejudice our rights to take subsequent action.</li>
                                                                <li>If any part of any provision of these Terms shall be or become invalid, unlawful or unenforceable to any extent, then the remainder of such provision and all other provisions of these Terms shall continue to be valid and enforceable to the fullest extent permitted by law.</li>
                                                                <li>These Terms, the Privacy Notice and Cookie Policy (the <strong>Policies</strong>) represent the entire agreement of you and us in relation to the subject matter of these Policies and supersedes any previous agreement between you and us in relation to the Site. Neither of you and us have relied upon any statement or representation made by the other in agreeing to enter these Policies.</li>
                                                            </ol>
                                                        </li>

                                                        <li><strong>JURISDICTION</strong>
                                                            <ol>
                                                                <li>These Terms shall be governed by, and construed in accordance with, English law and you and we submit to the exclusive jurisdiction of the English courts to settle any disputes which may arise in connection with these Terms. </li>
                                                            </ol>
                                                        </li>

                                                    </ol>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        );
    }
}

export default Terms;
