import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';


class WeatherAlerts extends Component {
    state = {
        clients : [
            { id : 1, img : "assets/images/clients/1.png" },
            { id : 2, img : "assets/images/clients/2.png" },
            { id : 3, img : "assets/images/clients/3.png" },
            { id : 4, img : "assets/images/clients/4.png" },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section-sm bg-light">
                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <p className="padding-t-15 mx-auto">Extreme weather events</p>
                                <Carousel
                                  showArrows={false}
                                  showStatus={false}
                                  autoPlay={true}
                                  showIndicators={true}
                                  showThumbs={false}
                                  interval={5000}
                                  infiniteLoop={true}
                                >
                                    <div className="carousel-div-weather">
                                        <h3>AUSTRALIA WINTER WHEAT PRODUCTION: MARKET YEAR 19/20 <span className="red-text">-14 MILLION METRIC TONS YOY</span></h3>
                                    </div>
                                    <div className="carousel-div-weather">
                                        <h3>USA CORN PRODUCTION: MARKET YEAR 19/20 <span className="red-text">-16.48 MILLION METRIC TONS YOY</span></h3>
                                    </div>
                                    <div className="carousel-div-weather">
                                        <h3>SOUTH AFRICA CORN PRODUCTION: MARKET YEAR 18/19 <span className="red-text">-1.28 MILLION METRIC TONS YOY</span></h3>
                                    </div>
                                    <div className="carousel-div-weather">
                                        <h3>USA SOYBEAN PRODUCTION: MARKET YEAR 19/20 <span className="red-text">-23.7 MILLION METRIC TONS YOY</span></h3>
                                    </div>
                                    <div className="carousel-div-weather">
                                        <h3>MALAYSIA PALM OIL PRODUCTION: MARKET YEAR 19/20 <span className="red-text">-1.8 MILLION METRIC TONS YOY</span></h3>
                                    </div>
                                    <div className="carousel-div-weather">
                                        <h3>FRANCE SUGARBEET PRODUCTION: MARKET YEAR 19/20 <span className="red-text">-6.4 MILLION METRIC TONS YOY</span></h3>
                                    </div>
                                    <div className="carousel-div-weather">
                                        <h3>INDIA SUGARCANE PRODUCTION: MARKET YEAR 19/20 <span className="red-text">-5 MILLION METRIC TONS YOY</span></h3>
                                    </div>
                                    <div className="carousel-div-weather">
                                        <h3>HONDURAS COFFEE ARABICA PRODUCTION: MARKET YEAR 19/20 <span className="red-text">-42 THOUSAND TONS YOY</span></h3>
                                    </div>
                                </Carousel>
                                <Link to="/signup" className="btn btn-custom margin-t-30 waves-effect waves-dark">Request a demo to learn more <i className="mdi mdi-arrow-right"></i></Link>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default WeatherAlerts;
