import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';

class Platform extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section" id="platform">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="5" className="order-2 order-lg-2">
                                <div className="features-box mt-5 mt-lg-0 left-content-col">
                                    <h3>The Platform</h3>
                                    <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                                        <li className="">Powerful data visualisation platform</li>
                                        <li className="">Aura yield forecast</li>
                                        <li className="">Supply & demand Data</li>
                                        <li className="">Agro-Meteorological data</li>
                                        <li className="">Agro-Meteorological reports</li>
                                        <li className="">Speak with Meteorologists</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={{size: 7, order: 1, }} xs={{order : 1}}>
                                <div className="features-img mx-auto mr-lg-0">
                                    <Carousel
                                      showArrows={false}
                                      showStatus={false}
                                      autoPlay={true}
                                      showIndicators={true}
                                      showThumbs={false}
                                      interval={10000}
                                      infiniteLoop={true}
                                    >
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '360px' }} src={window.StaticUrl + "aura-landing/img/platform/1.png"} />
                                        </div>
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '360px' }} src={window.StaticUrl + "aura-landing/img/platform/2.png"} />
                                        </div>
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '360px' }} src={window.StaticUrl + "aura-landing/img/platform/3.png"} />
                                        </div>
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '360px' }} src={window.StaticUrl + "aura-landing/img/platform/4.png"} />
                                        </div>
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '360px' }} src={window.StaticUrl + "aura-landing/img/platform/5.png"} />
                                        </div>
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '360px' }} src={window.StaticUrl + "aura-landing/img/platform/6.png"} />
                                        </div>
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '360px' }} src={window.StaticUrl + "aura-landing/img/platform/7.png"} />
                                        </div>
                                        <div className="carousel-div">
                                          <img style={{ width: 'auto', maxHeight: '360px' }} src={window.StaticUrl + "aura-landing/img/platform/8.png"} />
                                        </div>
                                    </Carousel>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Platform;
