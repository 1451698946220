import React, { Component } from 'react';
import {
    Navbar,
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Container,
    Collapse,
    Button
} from "reactstrap";
import ScrollspyNav from "./scrollSpy";

//Import Stickey Header
import StickyHeader from 'react-sticky-header';

class Navbar_Page extends Component {
    constructor(props){
        super(props);
        this.state = {
            navItems : [
                { id: 1 , idnm : "home", navheading: "Home" },
                { id: 2 , idnm : "casestudies", navheading: "Case Studies" },
                { id: 3 , idnm : "platform", navheading: "The Platform" },
                { id: 4 , idnm : "services", navheading: "Who is Aura for?" },
                { id: 5 , idnm : "yield", navheading: "Features" },
                { id: 8 , idnm : "contact", navheading: "Contact" },
                ],
            isOpenMenu :false
        };
    }

    toggle = () => {
        this.setState({ isOpenMenu: !this.state.isOpenMenu });
    }

    goToLogin = () => {
      this.props.history.push("/login")
    }

    goToSignup = () => {
      this.props.history.push("/signup")
    }

    render() {
        //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
        let TargetId = this.state.navItems.map((item) => {
            return(
                item.idnm
            )
        });

        return (
            <React.Fragment>
                <StickyHeader

                    header = {
                        <Navbar expand="lg" fixed="top" className={ this.props.navClass + " navbar-custom sticky sticky-dark"}>
                            <Container>
                                <NavbarBrand className="logo" href="/">
                                    <img src={window.StaticUrl + "img/auralogo.png"} alt="" />
                                </NavbarBrand>

                                <NavbarToggler className="p-0" onClick={this.toggle} ><i className="mdi mdi-menu"></i></NavbarToggler>

                                <Collapse id="navbarCollapse" isOpen={this.state.isOpenMenu} navbar>
                                    <ScrollspyNav
                                        scrollTargetIds={TargetId}
                                        activeNavClass="active"
                                        scrollDuration="800"
                                        headerBackground="true"
                                    >
                                        <Nav className="navbar-nav navbar-center" id="mySidenav">
                                            {this.state.navItems.map((item) => (
                                                <NavItem key={item.idnm} className={item.navheading === "Home" ? "active" : "" }>
                                                    <NavLink href={"#" + item.idnm} > {item.navheading}</NavLink>
                                                </NavItem>
                                            ))}
                                        </Nav>
                                    </ScrollspyNav>
                                    <div className="nav-button ml-auto">
                                        <Nav className="navbar-right" navbar>
                                            <NavItem key={'signin'}>
                                                <Button onClick={this.goToLogin} type="button" className="btn-custom navbar-btn btn-rounded waves-effect waves-dark btn-border">Login</Button>
                                            </NavItem>
                                            <NavItem key={'signup'}>
                                                <Button onClick={this.goToSignup} type="button" className="btn-custom navbar-btn btn-rounded waves-effect waves-light">Request a Demo</Button>
                                            </NavItem>
                                        </Nav>
                                    </div>

                                </Collapse>
                            </Container>
                        </Navbar>
                    }
                    stickyOffset = {-100}
                >
                </StickyHeader>
            </React.Fragment>
        );
    }
}

export default Navbar_Page;
